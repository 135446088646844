import {runOnDOMContentLoaded} from "./utilities";
import Choices from "choices.js";

const mainEvent = () => {
    for (const select of document.querySelectorAll('select')) {
        if (select.classList.contains("vanilla")) continue;

        new Choices(select, {
            removeItemButton: true,
            shouldSort: false
        });
    }
};

runOnDOMContentLoaded(mainEvent);
document.addEventListener("react:load", mainEvent);
