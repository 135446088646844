import * as Cookies from "js-cookie";
import { valueExists } from "./utilities";

export const setRefCookie = () => {
  const referrer = document.referrer;
  const substring = window.location.search.substring(1);

  const setSource = (value, param) => {
    if (!valueExists(Cookies.get(param))) {
      Cookies.set(param, value, { expires: 24 });
    }
  };

  setSource(substring, 'lawbite_params');
  setSource(referrer, 'lawbite_referrer');
};

export const forwardRefCookie = () => {
  const forwardElements = document.querySelectorAll('.forward-params');

  forwardElements.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const href = element.getAttribute('href');
      const params = href.split('?')[1];
      const paramSep = params && params.length ? '&' : '?';

      const newHref = href + refCookieParams(paramSep);
      window.location.href = newHref;
    });
  });
}

export const refCookieParams = (seperator = '?') => {
  const lawbiteParams = Cookies.get('lawbite_params') || 'utm=false';
  const lawbiteReferrer = Cookies.get('lawbite_referrer');
  let extendAnchor = seperator + lawbiteParams;

  if (lawbiteReferrer) {
    extendAnchor = extendAnchor + '&first_referral=' + lawbiteReferrer
  }

  return extendAnchor;
}
