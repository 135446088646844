import {rerunCallTracking} from "./call_tracking";
import * as controlModals from "./control_modals";
import * as controlChat from "./control_chat";
import {runOnDOMContentLoaded} from "./utilities";
import { setRefCookie, forwardRefCookie } from "./cookie";

controlModals.listenForTriggers();

const initialiseControlChat = () => {
  for (const element of document.querySelectorAll('.live-chat')) {
    element.addEventListener("click", (event) => {
      controlChat.expand();
      controlModals.close();
      
      event.preventDefault();
      event.stopImmediatePropagation(); 
    });
  }
};

// Mouseover on Navbar-menu will show full blur-bg
const darkOverlay = () => {
  const mouseTarget = document.querySelector('.header-menu');
  const overlay = document.querySelector('.overlay-dark');

  if (mouseTarget == null || overlay == null){ return false }
  
  mouseTarget.addEventListener('mouseenter', e => {
    overlay.style.display = 'block';
  });
  mouseTarget.addEventListener('mouseleave', e => {
    overlay.style.display = 'none';
  });
}

const mainEvent = () => {
  rerunCallTracking();
  initialiseControlChat();
  darkOverlay();
  setRefCookie();
  forwardRefCookie();
};

runOnDOMContentLoaded(mainEvent);
document.addEventListener("react:load", mainEvent);
