import {addLiveEventListeners} from "./utilities";
import {ReactModal} from "./types";

declare const reactModals: ReactModal[];

const waitTillReactLoaded = (f) => {
    const storage = (window as any);

    storage.reactLoadingTimer = setInterval(() => {
        const overlay = document.querySelector(".react-loading-overlay");

        if (overlay) {
            clearTimeout(storage.reactLoadingTimer);
            overlay.classList.add("enabled");

            clearTimeout(storage.reactLoadingTimer);
            storage.reactLoadingTimer = setInterval(() => {
                if (storage.reactReady) {
                    clearTimeout(storage.reactLoadingTimer);
                    overlay.classList.remove("enabled");
                    f();
                }
            }, 50);
        }
    }, 50);
};

const getExtraData = (element) => {
    const prefix = "reactModals";

    const getKeyName = (dataItem) => {
        const firstLetter = dataItem[prefix.length].toLowerCase();
        const restOfName = dataItem.slice(prefix.length + 1);
        return firstLetter + restOfName;
    };

    const data = {};

    for (const dataItem in element.dataset) {
        if (!dataItem.startsWith("reactModals")) continue;

        const key = getKeyName(dataItem);
        data[key] = element.dataset[dataItem];
    }

    return data;
};

const listenForClick = (reactModal: ReactModal): void => {
    addLiveEventListeners(`.${reactModal.classAttr}`, "click", function(clickEvent) {
        const extraData = getExtraData(clickEvent.target);
        open(reactModal, extraData);
        clickEvent.preventDefault();
    });
};

const openOnMatchingLocation = (reactModal: ReactModal): void => {
    if (location.hash === `#${reactModal.modal}` || location.pathname === `/${reactModal.pathname}`) {
        open(reactModal);
    }
};

export const listenForTriggers = (): void => {
    for (const reactModal of reactModals) {
        listenForClick(reactModal);
        openOnMatchingLocation(reactModal);
    }
};

export const open = (reactModal: ReactModal, extraData: {[key: string]: string}={}): void => {
    if(reactModal === null) {
        console.log("modal not found");
        return null;
    }

    const data = {modal: reactModal.modal, type: reactModal.type, extraData: extraData};

    waitTillReactLoaded(() => {
        document.dispatchEvent(new CustomEvent('open-modal', {detail: JSON.stringify(data)}));
    });
};

export const close = (): void => {
    open(null);
};
