/** adds a live event handler akin to jQuery's on() */
export const addLiveEventListeners = (selector, event, handler) => {
    document.querySelector("html").addEventListener(event, (event) => {
        let target = event.target;

        while (target != null) {
            const isMatch = target.matches(selector);

            if (isMatch){
                handler(event);
                return;
            }
            target = target.parentElement;
        }
    },true);
};

export const runOnDOMContentLoaded = event => {
    if (["complete", "loaded", "interactive"].includes(document.readyState)) {
        event();
    } else {
        window.addEventListener("DOMContentLoaded", event);
    }
};

export const addEventListenerAll = (selector: string, event: string, handler: EventListenerOrEventListenerObject): void => {
    document.querySelectorAll(selector).forEach(
        elem => elem.addEventListener(event, handler)
    );
};

export const valueExists = (value) => {
    return (value != null && value != '' && value != undefined)
}
